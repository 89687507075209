<template>
  <div>
    <breadcrumbs :items="breadcrumbs" />
    <Loader :visible="loading" />
    <div v-if="percentageImo === 100 && !loading">
      <v-row class="ma-0 pa-0" align="stretch">
        <ManagementFilter
          @loading="isLoading"
          :loading="loading"
          :business-areas="getCompanyBusinessAreas"
          :managements="getCompanyManagements"
        />
      </v-row>
      <v-row>
        <v-col md="4">
          <imo-summary
            :imo-result="Math.round(getIndexInfo.indexScore.score)"
            :dimensions="getIndexInfo.indexScore.dimensionScores"
            :maturity-level="getIndexInfo.imoLevelName"
            :maturity-level-text="getGeneralComment"
          />
        </v-col>
        <v-col md="8">
          <v-row>
            <v-col>
              <imo-results
                :most-critical-chain="getDimensionAdvices.CadenaDeValor"
                :most-critical-ecosistem="getDimensionAdvices.Ecosistema"
                :chain-subdimension="getIndexInfo.indexScore.dimensionScores[1].subdimensionScores"
                :ecosistem-subdimension="getIndexInfo.indexScore.dimensionScores[0].subdimensionScores"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <imo-recomendations
                :subdimension-recomendation="getDimensionAdvices.CadenaDeValor.mostCriticalVsOthers"
                :redirect="
                  getIndexInfo.imoLevelName === 'Preparada'
                    ? this.percentage !== 100
                      ? '/dti-form'
                      : '/plans'
                    : '/capacitaciones'
                "
                :recomendation="getGeneralAdvice"
                :maturity-level="getIndexInfo.imoLevelName"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import IMOSummary from "@/components/dashboards/IMO/IMOSummary.vue";
import IMORecomendations from "@/components/dashboards/IMO/IMORecomendations.vue";
import IMOResultsByDimension from "@/components/dashboards/IMO/IMOResultsByDimension.vue";
import Loader from "@/components/Loader";
import Breadcrumbs from "@/components/Breadcrumbs";
import ManagementFilter from "@/components/dashboards/IMTD/ManagementFilter.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    "imo-summary": IMOSummary,
    "imo-recomendations": IMORecomendations,
    "imo-results": IMOResultsByDimension,
    Loader,
    Breadcrumbs,
    ManagementFilter,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: "Inicio",
          disabled: false,
          exact: true,
          to: {
            name: "home",
          },
        },
        {
          text: "Dashboard IMO",
          disabled: true,
          exact: true,
          to: {
            name: "dashboard-imo",
          },
        },
      ],
      userId: localStorage.getItem("userId"),
      loading: true,
      error: false,
    };
  },
  async created() {
    this.init();
    await this.surveyComplete(this.userId);
    if (this.percentageImo !== 100) {
      this.$router.replace("/survey-imo");
    }
    let tokens = [];
    for (let i = 0; i < 2; i++) {
      tokens[i] = await this.$recaptcha("dashboard");
    }
    this.setBusinessAreas(tokens[0]);
    this.setManagements(tokens[1]);
    if (this.percentageImo === 100) {
      setTimeout(() => {
        this.getData();
      }, 2000);
    }
  },
  watch: {
    error() {
      if (this.error) {
        setTimeout(() => {
          this.getData();
        }, 3000);
      }
    },
  },
  computed: {
    ...mapGetters("ImoStore", ["getDimensionAdvices", "getGeneralAdvice", "getGeneralComment", "getIndexInfo"]),
    ...mapGetters("CompanyStore", ["getCompanyBusinessAreas", "getCompanyManagements"]),
    ...mapGetters("DigitalTransformationStore", ["percentage", "percentageImo"]),
  },
  methods: {
    ...mapActions("ImoStore", ["getRecomendations", "init"]),
    ...mapActions("DigitalTransformationStore", ["surveyComplete"]),
    ...mapActions("CompanyStore", ["setBusinessAreas", "setManagements"]),
    async getData() {
      await this.getRecomendations()
        .then(() => {
          this.loading = false;
          this.error = false;
        })
        .catch(() => {
          this.error = true;
        });
    },
    isLoading(event) {
      this.Loading = event;
    },
  },
};
</script>

<style></style>
