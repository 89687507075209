<template>
  <v-card class="imo-results px-2">
    <div v-if="this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl">
      <v-container>
        <v-row style="margin-top: 5px">
          <v-col cols="12" md="4" lg="4">
            <v-row width="100%" class="mb-6 ma-0 pa-0">
              <v-btn
                depressed
                rounded
                small
                :color="flagRanking == 1 ? '#119A24' : '#C5F1CB'"
                @click="
                  () => {
                    handleChangeFlag(1);
                  }
                "
                :style="
                  (flagRanking == 1 ? 'color: #FFFFFF;' : 'color: #0F841F;') +
                  ' font-family: Roboto Condensed; font-style: normal; font-weight: normal; font-size: 13px; line-height: 15px; display: flex; align-items: center;'
                "
              >
                Cadena de Valor
              </v-btn>
              <v-btn
                depressed
                rounded
                small
                :color="flagRanking == 2 ? '#119A24' : '#C5F1CB'"
                @click="
                  () => {
                    handleChangeFlag(2);
                  }
                "
                :style="
                  (flagRanking == 2 ? 'color: #FFFFFF;' : 'color: #0F841F;') +
                  '  font-family: Roboto Condensed; font-style: normal; font-weight: normal; font-size: 13px; line-height: 15px; display: flex; align-items: center; margin-left: 10px'
                "
              >
                Ecosistema
              </v-btn>
            </v-row>
            <v-row>
              <v-container>
                <div v-if="flagRanking == 1" class="">
                  <h3 class="title-text d-flex">
                    <span> Lo más crítico: {{ mostCriticalChain.dimensionName }} </span>
                    <v-tooltip bottom max-width="21em">
                      <template v-slot:activator="{ attrs, on }">
                        <v-img
                          class="ml-2"
                          :src="require('@/assets/img/icons/carbon_information.svg')"
                          max-width="17"
                          max-height="17"
                          style="cursor: pointer"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span class="text-center"
                        >Descripción de la dimensión con el Nivel de Madurez más bajo dentro de las demás
                        dimensiones.</span
                      >
                    </v-tooltip>
                  </h3>
                  <p class="body-text">
                    {{ mostCriticalChain.dimensionAdvice }}
                  </p>
                </div>
                <div v-if="flagRanking == 2" class="">
                  <h3 class="title-text d-flex">
                    <span>Lo más crítico: {{ mostCriticalEcosistem.dimensionName }}</span>
                    <v-tooltip bottom max-width="21em">
                      <template v-slot:activator="{ attrs, on }">
                        <v-img
                          class="ml-2"
                          :src="require('@/assets/img/icons/carbon_information.svg')"
                          max-width="17"
                          max-height="17"
                          style="cursor: pointer"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span class="text-center"
                        >Descripción de la dimensión con el Nivel de Madurez más bajo dentro de las demás
                        dimensiones.</span
                      >
                    </v-tooltip>
                  </h3>
                  <p class="body-text">
                    {{ mostCriticalEcosistem.dimensionAdvice }}
                  </p>
                </div>
              </v-container>
            </v-row>
          </v-col>
          <v-col cols="12" sm="4" md="8" lg="7" class="mt-n5">
            <div v-if="flagRanking == 1">
              <div v-if="flag == true" class="ma-0 pa-0">
                <RadarChart :chartdata="chainData" :labels="chainLabels" />
              </div>
            </div>
            <div v-if="flagRanking == 2">
              <div v-if="flag == true">
                <RadarChart2 :chartdata="ecosistemData" :labels="ecosistemLabels" />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else>
      <v-container>
        <v-row width="100%" class="mb-n6 ma-0 pa-0" justify="center">
          <v-btn
            depressed
            rounded
            small
            :color="flagRanking == 1 ? '#119A24' : '#C5F1CB'"
            @click="
              () => {
                handleChangeFlag(1);
              }
            "
            :style="
              (flagRanking == 1 ? 'color: #FFFFFF;' : 'color: #0F841F;') +
              ' font-family: Roboto Condensed; font-style: normal; font-weight: normal; font-size: 13px; line-height: 15px; display: flex; align-items: center;'
            "
          >
            Cadena de Valor
          </v-btn>
          <v-btn
            depressed
            rounded
            small
            :color="flagRanking == 2 ? '#119A24' : '#C5F1CB'"
            @click="
              () => {
                handleChangeFlag(2);
              }
            "
            :style="
              (flagRanking == 2 ? 'color: #FFFFFF;' : 'color: #0F841F;') +
              '  font-family: Roboto Condensed; font-style: normal; font-weight: normal; font-size: 13px; line-height: 15px; display: flex; align-items: center; margin-left: 10px'
            "
          >
            Ecosistema
          </v-btn>
        </v-row>
        <v-row>
          <v-col md="3"></v-col>
          <v-col cols="12" md="6" lg="5" class="">
            <div v-if="flagRanking == 1">
              <div v-if="flag == true">
                <RadarChart :chartdata="chainData" :labels="chainLabels" />
              </div>
            </div>
            <div v-if="flagRanking == 2">
              <div v-if="flag == true">
                <RadarChart2 :chartdata="ecosistemData" :labels="ecosistemLabels" />
              </div>
            </div>
          </v-col>
        </v-row>
        <v-expansion-panels flat>
          <v-expansion-panel @click="handleClick()">
            <v-expansion-panel-header>
              <span style="color: #7319d5" v-if="!expanded">Ver más</span>
              <span style="color: #7319d5" v-if="expanded">Ver menos</span>
              <template v-slot:actions>
                <v-icon color="#7319d5"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container>
                <div v-if="flagRanking == 1" class="">
                  <h3 class="title-text d-flex">
                    <span> Lo más crítico: {{ mostCriticalChain.dimensionName }} </span>
                    <v-tooltip bottom max-width="21em">
                      <template v-slot:activator="{ attrs, on }">
                        <v-img
                          class="ml-2"
                          :src="require('@/assets/img/icons/carbon_information.svg')"
                          max-width="17"
                          max-height="17"
                          style="cursor: pointer"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span class="text-center"
                        >Descripción de la dimensión con el Nivel de Madurez más bajo dentro de las demás
                        dimensiones.</span
                      >
                    </v-tooltip>
                  </h3>
                  <p class="body-text">
                    {{ mostCriticalChain.dimensionAdvice }}
                  </p>
                </div>
                <div v-if="flagRanking == 2" class="">
                  <h3 class="title-text d-flex">
                    <span>Lo más crítico: {{ mostCriticalEcosistem.dimensionName }}</span>
                    <v-tooltip bottom max-width="21em">
                      <template v-slot:activator="{ attrs, on }">
                        <v-img
                          class="ml-2"
                          :src="require('@/assets/img/icons/carbon_information.svg')"
                          max-width="17"
                          max-height="17"
                          style="cursor: pointer"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <span class="text-center"
                        >Descripción de la dimensión con el Nivel de Madurez más bajo dentro de las demás
                        dimensiones.</span
                      >
                    </v-tooltip>
                  </h3>
                  <p class="body-text">
                    {{ mostCriticalEcosistem.dimensionAdvice }}
                  </p>
                </div>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </div>
  </v-card>
</template>

<script>
import RadarChart from "@/components/charts/RadarChart";
import RadarChart2 from "@/components/charts/RadarChart2";
export default {
  components: {
    RadarChart,
    RadarChart2,
  },
  props: {
    mostCriticalChain: {
      type: Object,
      default: null,
    },
    mostCriticalEcosistem: {
      type: Object,
      default: null,
    },
    chainSubdimension: {
      type: Array,
      default: null,
    },
    ecosistemSubdimension: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      flag: false,
      flagChart: false,
      flagRanking: 1,
      chainData: [],
      chainLabels: [],
      ecosistemData: [],
      ecosistemLabels: [],
      expanded: false,
    };
  },
  methods: {
    handleClick() {
      this.expanded = !this.expanded;
    },
    handleChangeFlag(flag) {
      this.flagRanking = flag;
    },
    handleData() {
      if (this.chainSubdimension) {
        this.chainData = [];
        this.chainLabels = [];
        let subdimensions = [];

        if (this.chainSubdimension.length === 5) {
          subdimensions = this.chainSubdimension;
        } else {
          subdimensions = this.ecosistemSubdimension;
        }
        subdimensions.forEach((element) => {
          let rounded = Math.round(element.score);
          let label = "";
          if (element.dimensionCode === "imo-index-v1-D02_01") {
            label = "Logística interna";
          }
          if (element.dimensionCode === "imo-index-v1-D02_02") {
            label = "Operaciones";
          }
          if (element.dimensionCode === "imo-index-v1-D02_03") {
            label = "Logística Externa";
          }
          if (element.dimensionCode === "imo-index-v1-D02_04") {
            label = "Marketing y Ventas";
          }
          if (element.dimensionCode === "imo-index-v1-D02_05") {
            label = "Servicios";
          }
          this.chainData.push(rounded);
          this.chainLabels.push(label);
        });
      }
      if (this.ecosistemSubdimension) {
        this.ecosistemData = [];
        this.ecosistemLabels = [];
        let subdimensions = [];

        if (this.ecosistemSubdimension.length === 4) {
          subdimensions = this.ecosistemSubdimension;
        } else {
          subdimensions = this.chainSubdimension;
        }
        subdimensions.forEach((element) => {
          let rounded = Math.round(element.score);
          let label = "";
          if (element.dimensionCode === "imo-index-v1-D01_01") {
            label = "Liderazgo";
          }
          if (element.dimensionCode === "imo-index-v1-D01_02") {
            label = "Estrategia";
          }
          if (element.dimensionCode === "imo-index-v1-D01_03") {
            label = "Dirección de personas";
          }
          if (element.dimensionCode === "imo-index-v1-D01_04") {
            label = "Organización";
          }

          this.ecosistemData.push(rounded);
          this.ecosistemLabels.push(label);
        });
      }
      this.flag = true;
    },
  },
  watch: {
    chainSubdimension() {
      this.handleData();
    },
    ecosistemSubdimension() {
      this.handleData();
    },
  },
  created() {
    this.handleData();
  },
};
</script>

<style scoped>
.imo-results {
  border: 1px solid #7319d5;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}
.title-text {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
}
.body-text {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #333333;
}
</style>
