<template>
  <v-card class="imo-summary pa-6">
    <div v-if="!mobile">
      <div class="text-center">Índice de Madurez Organizacional (IMO)</div>
      <div class="d-flex justify-center mt-3">
        <v-progress-circular :size="183" color="#7319D5" :value="imoResult" :width="9">
          <v-progress-circular :size="150" color="#119A24" :value="chainValue" :width="9">
            <v-progress-circular :size="118" color="#AEEA00" :value="ecosistemValue" :width="9">
              <span class="circle-label"> {{ imoResult }}% </span></v-progress-circular
            ></v-progress-circular
          ></v-progress-circular
        >
      </div>
      <div class="mt-5 text-center">Dimensiones IMO</div>
      <div class="mt-1 d-flex justify-center">
        <div class="text-center">
          <v-progress-circular :size="64" color="#119A24" :value="chainValue" :width="9">
            <span class="circle-label-sm"> {{ chainValue }}% </span></v-progress-circular
          >
          <div class="circle-sub-label-sm">Cadena de Valor</div>
        </div>
        <div class="text-center ml-12">
          <v-progress-circular :size="64" color="#AEEA00" :value="ecosistemValue" :width="9">
            <span class="circle-label-sm"> {{ ecosistemValue }}% </span>
          </v-progress-circular>
          <div class="circle-sub-label-sm">Ecosistema</div>
        </div>
      </div>
      <div class="mt-9 mb-2 text-center">
        Nivel de Madurez: <strong> {{ maturityLevel }} </strong>
      </div>
      <div>
        <imo-stepper :steps="['Insuficiente', 'Suficiente', 'Recomendable', 'Preparada']" :level="maturityLevel" />
      </div>
      <div class="body-text mt-4">
        {{ maturityLevelText }}
      </div>
    </div>
    <div v-else>
      <div class="text-center">Índice de Madurez Organizacional (IMO)</div>
      <div class="d-flex justify-center mt-3">
        <v-progress-circular :size="183" color="#7319D5" :value="imoResult" :width="9">
          <v-progress-circular :size="150" color="#119A24" :value="chainValue" :width="9">
            <v-progress-circular :size="118" color="#AEEA00" :value="ecosistemValue" :width="9">
              <span class="circle-label"> {{ imoResult }}% </span></v-progress-circular
            ></v-progress-circular
          ></v-progress-circular
        >
      </div>
      <v-expansion-panels flat>
        <v-expansion-panel @click="handleClick()">
          <v-expansion-panel-header>
            <span style="color: #7319d5" v-if="!expanded">Ver más</span>
            <span style="color: #7319d5" v-if="expanded">Ver menos</span>
            <template v-slot:actions>
              <v-icon color="#7319d5"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="mt-5 text-center">Dimensiones IMO</div>
            <div class="mt-1 d-flex justify-center">
              <div class="text-center">
                <v-progress-circular :size="64" color="#119A24" :value="chainValue" :width="9">
                  <span class="circle-label-sm"> {{ chainValue }}% </span></v-progress-circular
                >
                <div class="circle-sub-label-sm">Cadena de Valor</div>
              </div>
              <div class="text-center ml-12">
                <v-progress-circular :size="64" color="#AEEA00" :value="ecosistemValue" :width="9">
                  <span class="circle-label-sm"> {{ ecosistemValue }}% </span>
                </v-progress-circular>
                <div class="circle-sub-label-sm">Ecosistema</div>
              </div>
            </div>
            <div class="mt-9 mb-2 text-center">
              Nivel de Madurez: <strong> {{ maturityLevel }} </strong>
            </div>
            <v-row class="mt-3">
              <v-col cols="12" xs="6" class="pl-2 pr-2">
                <imo-stepper
                  :steps="['Insuficiente', 'Suficiente', 'Recomendable', 'Preparada']"
                  :level="maturityLevel"
                />
              </v-col>
            </v-row>
            <div class="body-text mt-4">
              {{ maturityLevelText }}
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-card>
</template>

<script>
import IMOStepper from "@/components/dashboards/IMO/IMOStepper.vue";
export default {
  components: {
    "imo-stepper": IMOStepper,
  },
  props: {
    imoResult: {
      type: Number,
      default: 0,
    },
    dimensions: {
      type: Array,
      default: () => [],
    },
    maturityLevel: {
      type: String,
      default: null,
    },
    maturityLevelText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      chainValue: 0,
      ecosistemValue: 0,
      expanded: false,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
  watch: {
    dimensions() {
      this.getDimensionScores();
    },
  },
  methods: {
    handleClick() {
      this.expanded = !this.expanded;
    },
    getDimensionScores() {
      if (this.dimensions[0].dimensionCode === "imo-index-v1-D01") {
        this.ecosistemValue = Math.round(this.dimensions[0].score);
        this.chainValue = Math.round(this.dimensions[1].score);
      } else {
        this.ecosistemValue = Math.round(this.dimensions[1].score);
        this.chainValue = Math.round(this.dimensions[0].score);
      }
    },
  },
  created() {
    this.getDimensionScores();
  },
};
</script>

<style scoped>
.imo-summary {
  border: 1px solid #7319d5;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.12);
  border-radius: 10px;

  height: 100%;
}
.circle-label {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 120%;
  color: #333333;
}
.circle-label-sm {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
.circle-label-sm {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}
.circle-sub-label-sm {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #999999;
  margin-top: 5px;
}
.body-text {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #333333;
}
</style>
