<template>
  <v-card class="imo-recomendations">
    <v-container>
      <v-card-title class="title-text"
        >Recomendaciones
        <v-tooltip bottom max-width="21em">
          <template v-slot:activator="{ attrs, on }">
            <v-img
              class="ml-2"
              :src="require('@/assets/img/icons/carbon_information.svg')"
              max-width="17"
              max-height="17"
              style="cursor: pointer"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span class="text-center"
            >Recomendaciones para impulsar las mejoras necesarias de los procesos sobre las variables analizadas, en
            especial las que están en niveles bajos.</span
          >
        </v-tooltip>
      </v-card-title>
      <div v-if="mobile">
        <v-expansion-panels flat>
          <v-expansion-panel @click="handleClick()">
            <v-expansion-panel-header>
              <span style="color: #7319d5" v-if="!expanded"> Ver más</span>
              <span style="color: #7319d5" v-if="expanded"> Ver menos</span>
              <template v-slot:actions>
                <v-icon color="#7319d5"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-tabs v-model="tab" color="#119A24" show-arrows>
                <v-tab v-for="(item, index) in this.namesArray" :key="index">
                  {{ item[2] }}
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item v-for="(item, index) in this.namesArray" :key="index">
                  <v-card flat>
                    <v-card-text
                      ><p class="body-text">{{ item[1] }}</p></v-card-text
                    >
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
              <v-card-text>
                <p class="body-text">
                  {{ recomendation }}
                </p>
              </v-card-text>
              <v-row class="pb-6">
                <v-col cols="1" align-self="center" class="pa-5"> <v-icon color="#7319D5">arrow_forward</v-icon></v-col>
                <v-col lg="7">
                  <div class="body-text">
                    <b>{{
                      maturityLevel === "Preparada"
                        ? "Conoce tu madurez en transformación digital, encuentra soluciones para acelerar tu digitalización y transforma tu empresa."
                        : "Mejora tus resultados del Índice de Madurez Organizacional con formación especializada."
                    }}</b>
                  </div>
                </v-col>
                <v-col lg="4" align-self="center">
                  <v-btn @click.prevent="redirecting()" rounded color="#7319D5" dark class="button-text">
                    {{ maturityLevel === "Preparada" ? "comenzar TRANSFORMACIÓN" : "mejorar resultados" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div v-else>
        <v-tabs v-model="tab" color="#119A24" show-arrows>
          <v-tab v-for="(item, index) in this.namesArray" :key="index">
            {{ item[2] }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(item, index) in this.namesArray" :key="index">
            <v-card flat>
              <v-card-text
                ><p class="body-text">{{ item[1] }}</p></v-card-text
              >
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <v-card-text>
          <p class="body-text">
            {{ recomendation }}
          </p>
        </v-card-text>
        <v-row class="pb-6">
          <v-col cols="1" align-self="center" class="pa-5"> <v-icon color="#7319D5">arrow_forward</v-icon></v-col>
          <v-col lg="7">
            <div class="body-text">
              <b>{{
                maturityLevel === "Preparada"
                  ? "Conoce tu madurez en transformación digital, encuentra soluciones para acelerar tu digitalización y transforma tu empresa."
                  : "Mejora tus resultados del Índice de Madurez Organizacional con formación especializada."
              }}</b>
            </div>
          </v-col>
          <v-col lg="4" align-self="center">
            <v-btn @click.prevent="redirecting()" rounded color="#7319D5" dark class="button-text">
              {{ maturityLevel === "Preparada" ? "comenzar TRANSFORMACIÓN" : "mejorar resultados" }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-dialog v-model="showModal" max-width="30em" persistent>
      <v-card class="pt-8 pb-8">
        <v-card-title class="d-block">
          <h5 class="text-h5 tway-header-1 text-center px-15" style="word-break: break-word">Próximamente</h5>
        </v-card-title>
        <v-card-text class="text-center mt-7">
          <div class="px-md-10 px-0">
            <v-img :src="require(`@/assets/img/coming-soon-imo.svg`)" contain :max-height="130" />
            <div class="mt-5 text-center text-oboarding">
              Pronto actualizaremos las capacitaciones y cursos de INACAP. ¡No te los pierdas!
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            large
            rounded
            color="tway-violet"
            class="white--text roboto font-weight-bold"
            min-width="120"
            @click="showModal = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  props: {
    subdimensionRecomendation: {
      type: Object,
      default: () => {},
    },
    recomendation: {
      type: String,
      default: null,
    },
    redirect: {
      type: String,
      default: null,
    },
    maturityLevel: {
      type: String,
      default: null,
    },
  },
  created() {
    this.keysArray = [];
    this.namesArray = [];
    this.convertObject();
  },
  data() {
    return {
      tab: null,
      keysArray: [],
      namesArray: [],
      showModal: false,
      expanded: false,
    };
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
  },
  watch: {
    subdimensionRecomendation() {
      if (this.subdimensionRecomendation) {
        this.keysArray = [];
        this.namesArray = [];
        this.convertObject();
      }
    },
  },
  methods: {
    handleClick() {
      this.expanded = !this.expanded;
    },
    convertObject() {
      this.keysArray = Object.entries(this.subdimensionRecomendation);
      this.keysArray.forEach((element) => {
        if (element[0] === "imo-index-v1-D02_01") {
          this.namesArray.push([...element, "Logística interna"]);
        }
        if (element[0] === "imo-index-v1-D02_02") {
          this.namesArray.push([...element, "Operaciones"]);
        }
        if (element[0] === "imo-index-v1-D02_03") {
          this.namesArray.push([...element, "Logística Externa"]);
        }
        if (element[0] === "imo-index-v1-D02_04") {
          this.namesArray.push([...element, "Marketing y Ventas"]);
        }
        if (element[0] === "imo-index-v1-D02_05") {
          this.namesArray.push([...element, "Servicios"]);
        }
      });
    },

    redirecting() {
      if (this.maturityLevel.toLowerCase() !== "preparada") {
        this.showModal = true;
        return;
      }
      if (this.redirect) {
        if (this.redirect === "/dti-form" || this.redirect === "/plans") {
          this.$router.replace({ path: this.redirect });
        }
      }
    },
  },
};
</script>

<style scoped>
.imo-recomendations {
  border: 1px solid #7319d5;
  box-shadow: 0px 5px 14px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  height: 100%;
}
.title-text {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #333333;
}
.body-text {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #333333;
}
.button-text {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.tab-title {
  font-family: Josefin Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}
</style>
