<template>
  <div class="row d-flex justify-center">
    <div class="stepper-wrapper ml-1 mr-1" style="">
      <div
        :class="['stepper-item', 'px-3', step === level ? 'active' : null]"
        v-for="(step, index) in steps"
        :key="index"
      >
        <v-img
          v-if="step === level"
          :src="require('@/assets/img/icons/carbon_location.svg')"
          max-width="28"
          style="position: absolute; bottom: 48px"
        />
        <div class="step-counter"></div>
        <div class="stepperText">{{ step }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      default: () => {
        [];
      },
      require: true,
    },
    level: {
      type: String,
      default: null,
    },
  },
};
</script>
<style>
.stepperText {
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  text-align: center;
  text-transform: uppercase;
  /* Gris Oscuro / Gris Oscuro 3 */
  color: #999999;
}
.stepper-wrapper {
  margin-top: 10%;
  display: flex;
  margin-bottom: 20px;
}
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 1;

  @media (max-width: 768px) {
    font-size: 10px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 2px solid #cccccc;
  width: 100%;
  top: 15px;

  left: -50%;
  z-index: -1;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border: 1px dashed #cccccc;
  width: 100%;
  top: 15px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #cccccc;
  margin-bottom: 6px;
}

.stepper-item.active .stepperText {
  color: #333333;
  font-weight: bold;
}

.stepper-item.completed .step-counter {
  background-color: #cccccc;
}
.stepper-item.active .step-counter {
  background-color: #aeea00;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border: 1px dashed #7319d5;
  width: 100%;
  top: 15px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}
</style>
