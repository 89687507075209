<script>
import { Radar } from "vue-chartjs";

export default {
  extends: Radar,
  props: {
    chartdata: {
      type: Array,
      default: null,
    },
    labels: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      currentChart: null,
    };
  },
  methods: {
    createChart() {
      if (this.currentChart) {
        this.currentChart.destroy();
      }
      this.currentChart = this.renderChart(
        {
          labels: [
            `${this.labels[0]} ${this.chartdata[0]}%`,
            `${this.labels[1]} ${this.chartdata[1]}%`,
            `${this.labels[2]} ${this.chartdata[2]}%`,
            `${this.labels[3]} ${this.chartdata[3]}%`,
            `${this.labels[4]} ${this.chartdata[4]}%`,
          ],
          datasets: [
            {
              backgroundColor: "rgba(125, 0, 255, 0.15)",
              borderColor: "rgb(22, 198, 46)",
              data: this.chartdata,
            },
          ],
        },
        {
          responsive: true,
          legend: {
            display: false,
          },
          scale: {
            ticks: {
              beginAtZero: true,
              max: 100,
              min: 0,
            },
          },
        }
      );
    },
  },
  mounted() {
    if (this.chartdata && this.labels) {
      this.createChart();
    }
  },
  watch: {
    chartdata() {
      this.createChart();
    },
    labels() {
      this.createChart();
    },
  },
};
</script>
